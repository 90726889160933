.custom-modal-content {
    width: 1000px; /* Increase the modal width */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
    padding: 20px; /* Add some padding for better aesthetics */
  }
  
.custom-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust the gap between rows */
    max-height: 60vh; /* Ensure modal body doesn't exceed viewport height */
    overflow-y: auto; /* Enable scrolling if content overflows */
  }
  
  .form-row {
    display: flex;
    gap: 30px; /* Adjust the gap between columns */
    flex-wrap: wrap; /* Allow wrapping to next line if necessary */
  }
  
  .form-row > * {
    flex: 1; /* Ensure all children take equal width */
    min-width: 0; /* Prevent items from overflowing container */
  }
.form-row2{
  margin-top: 24px;
}


  