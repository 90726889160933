@tailwind base;
@tailwind components;
@tailwind utilities;

.menu-items-wrapper{
    padding-top: 0px !important;
}
 
.report-heading{
    color: rgb(107, 69, 188);
}
 
#add-user-btn,#apply-btn,#pagination li:nth-child(3){
    background-color: rgb(107, 69, 188);
}
#pagination li:nth-child(3){
    border-radius: 10px;
}
 
#add-user-btn:hover{
    background-color: rgb(93, 62, 159);
}

.admin{
    font-size: 20px;
    color: #36A2EB !important;
    font-weight: bold;
}

