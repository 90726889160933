.attendance-wrapper {
  .report-heading {
    // color: #ff6347; 
    color: #36A2EB;
    margin-top: 20px;   
    margin-bottom: 20px; 
    margin-left: 30px;   
  
  }
  
  .leave-apply__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include media-min-lg {
      flex-direction: row;
    }
    @include media-min-xl {
      gap: 2rem;
    }
    .header-wrapper {
      h2 {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.6;
      }
    }

    .leave-apply__wrapper {
      width: 100%;
      @include media-min-lg {
        width: calc(60% - 0.5rem);
      }
      @include media-min-xl {
        width: calc(60% - 1rem);
      }
    }
    .my-teammate__wrapper {
      width: 100%;
      @include media-min-lg {
        width: calc(40% - 0.5rem);
      }
      @include media-min-xl {
        width: calc(40% - 1rem);
      }
    }
  }
}
