/* ScheduleForm.css */

.formTitle {
    color: #36A2EB;
    margin-top: 15px;
    margin-bottom: -25px;
    margin-left: 40px;
    font-size: larger;
    font-style: oblique;
    width: 100%;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 20px;
  }
  
  .card {
    width: 850px;
    height: 450px;
    padding: 40px;
    margin-top: -70px;
  }
  
  .formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .label {
    width: 150px;
    text-align: right;
    margin-right: 20px;
  }
  
  .inputContainer {
    width: 250px;
    text-align: center;
  }
